<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :points="classify.points"
      :breadcrumbs="crumbs"
    />
    <land-section
      id="content_detail"
      :space="sectionSpace"
    >
      <v-container
        style="max-width: 900px; padding: 0;"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <div class="d-flex flex-column">
              <v-img
                v-if="cntInfo.cover"
                :src="cntInfo.cover"
                cover
                height="100%"
              />
              <v-img
                v-for="(img, ind) in thumbs"
                :key="ind"
                :src="img"
                cover
                height="100%"
              />
              <div
                v-if="relations.length"
                class="d-flex flex-column mt-8"
              >
                <land-title
                  title="其他应用场景"
                  space="2"
                />
                <div
                  v-for="(rel, relInd) in relations"
                  :key="relInd"
                  class="d-flex flex-row align-center py-2"
                >
                  <span class="blue-grey--text pr-2">{{ `${relInd + 1}.` }}</span>
                  <span
                    class="blue--text cursor-handle"
                    @click="onRelation(rel)"
                  >
                    {{ rel.title }}
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
  </section>
</template>

<script>
  // import lib from '@/api/co.lib'
  import data from '@/data/co.data'
  // import mixPreset from '@/pages/mixins/mix.preset'

  import mixSceneBanner from '@/pages/mixins/scene/mix.scene.banner'

  export default {
    metaInfo: { title: '应用场景' },
    mixins: [
      mixSceneBanner
    ],
    data () {
      return {
        navMeta: {},
        cntInfo: {},
        thumbs: [],
        crumbs: [],
        defId: '10007',
        relations: [],
        schemes: [],
      }
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      },
      sectionSpace () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 0
        } else {
          return 40
        }
      }
    },
    created () {
      const contentId = this.$route.query.contentId || this.defId
      // const name = mixPreset.DetailPages.Scene
      // this.navMeta = JSON.parse(lib.cookie.get(name))

      this.getContent(contentId)
      this.configCrumbs()

      this.configBanner()
    },
    methods: {
      onRelation (item) {
        const contentId = item.contentId || this.defId
        this.getContent(contentId)
        this.configCrumbs()
      },
      getContent (contentId) {
        this.cntInfo = data.scene.getItem(contentId)
        this.thumbs = this.cntInfo.thumbs || []
        this.relations = this.cntInfo.relations || []
        this.schemes = this.cntInfo.schemes || []
        // console.log('this.cntInfo: %o', this.cntInfo)
      },
      configCrumbs () {
        this.crumbs = []

        this.crumbs.push(data.nav.home)
        this.crumbs.push(data.nav.scene)
        this.crumbs.push({
          ...data.nav.current,
          text: this.cntInfo.title
        })
      },
      configBanner () {
        this.banItems = [
          {
            bannerId: '2003',
            cover: this.cntInfo.banner,
            title: '应用场景',
          }
        ]
      },
    },
  }
</script>

<style scoped>
  .cursor-handle {
    cursor: pointer;
  }
</style>
